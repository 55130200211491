import { createStore } from 'vuex'

export default createStore({
  state: {
    cate_id: '', // 类别 id
  },
  mutations: {
    UpdateCateId(state, data) {
      if(!data) return
      state.cate_id = data
    }
  },
  actions: {
  },
  modules: {
  }
})
