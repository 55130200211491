import request from '../plugin/axios/index.js'
import Global from "../Global.js";

export function businessGet(url) {
    let openid = '';
    if (localStorage.getItem(Global.GlobalLocalName2)) {
        openid = JSON.parse(localStorage.getItem(Global.GlobalLocalName2)).openid;
    }
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers: {
            'openid': openid
        },
        method: 'get',
    })
}
export function businessPost(url, data) {
    let openid = '';
    if (localStorage.getItem(Global.GlobalLocalName2)) {
        openid = JSON.parse(localStorage.getItem(Global.GlobalLocalName2)).openid;
    }
    let msg = data;
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers: {
            'openid': openid
        },
        method: 'post',
        data: msg,
    })
}

export function businessPut(url, data) {
    let openid = '';
    if (localStorage.getItem(Global.GlobalLocalName2)) {
        openid = JSON.parse(localStorage.getItem(Global.GlobalLocalName2)).openid;
    }
    let msg = data;
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers: {
            'openid': openid
        },
        method: 'put',
        data: msg,
    })
}

export function businessDel(url, data) {
    let openid = '';
    if (localStorage.getItem(Global.GlobalLocalName2)) {
        openid = JSON.parse(localStorage.getItem(Global.GlobalLocalName2)).openid;
    }
    let msg = data;
    return request({
        url: process.env.VUE_APP_API_URL + url,
        headers: {
            'openid': openid
        },
        method: 'delete',
        data: msg,
    })
}
